import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {FormGroup, Label, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {infoban} from "../../../../helpers/constants";




export default function OrganizationCordBancaires(props) {
    const {register, handleSubmit, errors, setValue, reset} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [listBankDetails, setListBankDetails] = React.useState([]);

    const saveOrganization = data => {
        console.log("log bank details : ", data);
        props.saveOrganization({
            bankDetails: [{
                iban: data.iban,
                rib: data.rib,
                bic: data.bic,
                owner: data.owner,
                bank: data.bank
            }]
        });
    };

    useEffect(() => {
        register({name: "bank"});
        register({name: "owner"});
        register({name: "iban"});
        register({name: "rib"});
        register({name: "bic"});
    });

    const handleChangeAffiliationInfBank = selectedOption => {
        console.log("affiliation : information bancaire", selectedOption);
        if (selectedOption !== null) {
            setValue("bank", selectedOption.object.bank);
            setValue("owner", selectedOption.object.owner);
            setValue("iban", selectedOption.object.iban);
            setValue("rib", selectedOption.object.rib);
            setValue("bic", selectedOption.object.bic);
        } else {
            setValue("bank", undefined);
        }
    };

    const addBank = data => {
        console.log("on submit data bank details", data);

        let bankDetailsObj = {

            id: listBankDetails.length,
            iban: data.iban,
            rib: data.rib,
            bic: data.bic,
            owner: data.owner,
            bank: data.bank
        };
        setListBankDetails(listBankDetails.concat(bankDetailsObj));

        reset();
    };

    const deleteElementFromList = (key, list) => {
        console.log("key", key);
        let arr = [];
        list.forEach((value, keyLoop) => {
            if (key !== keyLoop) {
                arr.push(value);
            }
        });
        return arr;
    };


    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-3 aligner-wrapper text-center">
                                    <img className="pt-4"
                                         src={infoban}
                                         style={{width:"140px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-20 bold-text">Coordonnées bancaires</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(saveOrganization)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            {props.organizationObj.affiliation !== undefined && props.organizationObj.affiliation !== null && (
                                                <FormGroup>
                                                    <IntlMessages id="setting.common.cord.bancaires.affiliation"/>
                                                    <AdaSelect
                                                        name="bank"
                                                        errors={errors}
                                                        isClearable
                                                        options={props.informationBancaireAffiliation}
                                                        onChange={handleChangeAffiliationInfBank}
                                                        innerRef={register}
                                                    />
                                                </FormGroup>
                                            )}

                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.titulaire"/>
                                                <AdaInputText
                                                    name="owner"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.banque"/>
                                                <AdaInputText
                                                    name="bank"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                    <IntlMessages id="setting.organization.contacts.rib"/>
                                                    <AdaInputText
                                                        name="rib"
                                                        errors={errors}
                                                        innerRef={register}
                                                    />
                                            </FormGroup>


                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.iban"/>
                                                <AdaInputText
                                                    name="iban"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                        <IntlMessages id="setting.organization.contacts.bic"/>
                                                        <AdaInputText
                                                            name="bic"
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                            </FormGroup>

                                            <FormGroup>
                                                <AdaButton type="submit" className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.common.creer.organization"/>
                                                </AdaButton>

                                                <AdaButton onClick={() => props.step("4")}
                                                           className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.common.precedent.contact"/>
                                                </AdaButton>

                                                <AdaButton
                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                    onClick={props.cancelAction}
                                                >
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>

                                            </FormGroup>

                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
