import React, {useEffect, useState} from "react";
import { Col, CustomInput, Row, FormGroup } from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../../service/host";
import {Controller} from "react-hook-form";
import * as service from "../../../../service/crud.service";
import UserInfoWrapper from "./userinfo.style";

export default function UpdateInformation({icon, title,
  organization,
  errors,
  register,
  setValue,
  control,
                                              dispatch
}) {

    const [actList, setActList] = useState([]);
    const [affiliationList, setAffiliationList] = useState([]);

    useEffect(()=>{
        
        if(organization.id){
            console.log("in update information", organization)
            service.getAllForSelect(getHostByAppName(NOMENCLATURE_NAME), `company_activity_areas`, callback)
            service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, callbackOrg)
            dispatch({  "activityArea" : organization.activityArea ? organization.activityArea : undefined,
                        "affiliate" : organization.affiliate ? organization.affiliate : undefined})
        }
    },[])

    const callback = data =>{
        setActList(data)
        let obj = data.filter(obj => obj.key === organization.activityArea)[0];
        console.log("obj", obj, data, organization.activityArea)
        setValue("act", obj)
    }

    const callbackOrg = data =>{
        setAffiliationList(data)
        let obj = data.filter(obj => obj.key === organization.affiliate)[0];
        console.log("obj", obj, data, organization.affiliate)
        setValue("aff", obj)
    }

    const handleChangeActivity = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("activityArea", selectedOption);
        dispatch({"activityArea" : selectedOption.value})
    };
    const handleChangeAffiliation = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("affiliate", selectedOption);
        dispatch({"affiliate" : selectedOption.value})
    };

  return (
    <div>


        <UserInfoWrapper className="fill-height">
            <div className="card roe-shadow-2 fill-height">
                <div className="card-body">

                    <div className="row">
                        <div className="col-md-4 aligner-wrapper text-center">
                            <img
                                src={icon}
                                style={{width:"140px"}}
                            />
                            <div style={{marginTop:"10px"}}>
                                <h3 className="card-title fs-25 bold-text">{title}</h3>
                            </div>
                        </div>
                        <div className="col-md-4 legend-wrapper">
                            <div className="d-flex flex-column justify-content-center mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="description"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={organization.description}
                                    />
                                </div>
                                <small className="text-muted ml-3">Description</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="contactInformation.phone"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={organization.contactInformation.phone}
                                    />
                                </div>
                                <small className="text-muted ml-3">Téléphone</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="contactInformation.fax"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={organization.contactInformation.fax}
                                    />
                                </div>
                                <small className="text-muted ml-3">Fax</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="web"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={organization.web}
                                    />
                                </div>
                                <small className="text-muted ml-3">Site web</small>
                            </div>
                        </div>

                        <div className="col-md-4 legend-wrapper">
                            <div className="d-flex flex-column justify-content-center ">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <Controller
                                        as={<AdaSelect name={"aff"} />}
                                        name={"aff"}
                                        isClearable
                                        errors={errors}
                                        options={affiliationList}
                                        onChange={handleChangeAffiliation}
                                        control={control}
                                        innerRef={register}
                                    />
                                </div>
                                <small className="text-muted ml-3">Affiliation</small>
                            </div>

                            <div className="d-flex flex-column justify-content-center  border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="contactInformation.email"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={organization.contactInformation.email}
                                    />
                                </div>
                                <small className="text-muted ml-3">Email</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <Controller
                                        as={<AdaSelect name={"act"} />}
                                        name={"act"}
                                        isClearable
                                        errors={errors}
                                        options={actList}
                                        onChange={handleChangeActivity}
                                        control={control}
                                        innerRef={register}
                                    />
                                </div>
                                <small className="text-muted ml-3">Secteur d'activité</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center  border-top py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                    <CustomInput
                                        type="switch"
                                        id="statusInfClt"
                                        name="enable"
                                        defaultChecked={organization.enable}
                                        innerRef={register}
                                        label={<IntlMessages id="setting.organization.actif" />}
                                    />
                                </div>
                                <small className="text-muted ml-3">Statut</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserInfoWrapper>
    </div>
  );
}
