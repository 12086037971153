import React, { useEffect } from "react";
import { FormGroup, Label, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import { AdaButton, AdaInputText } from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import { useHistory } from "react-router";
import {addressPostal} from "../../../../helpers/constants";

export default function OrganizationAdressePostal(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [errorMessage, setErrorMessage] = React.useState("");

  const onSubmit = data => {
    props.updateAction({
      address: {
        number: data.number,
        street: data.street,
        city: data.city,
        zipCode: data.zipCode,
        provence: data.provence,
        country: data.country
      },
    });

    props.step("3");
  };

  useEffect(() => {
    register({ name: "number" });
    register({ name: "street" });
    register({ name: "city" });
    register({ name: "zipCode" });
    register({ name: "provence" });
    register({ name: "country" });
    register({ name: "numberB" });
    register({ name: "streetB" });
    register({ name: "cityB" });
    register({ name: "zipCodeB" });
    register({ name: "provenceB" });
    register({ name: "countryB" });
  }, []);


  return (
    <div>
      <div>
        <div className="row ma-0">
          <div className="col-sm-12 ptb-15">
            <div className="roe-card-style col-sm-12 row">
              <div className="roe-card-body col-sm-4">
                <div className="pl-5 pt-1 aligner-wrapper text-center">
                  <img className="pt-1"
                       src={addressPostal}
                       style={{width:"140px"}}
                  />
                  <div style={{marginTop:"10px"}}>
                    <h3 className="card-title fs-20 bold-text">Adresse postal</h3>
                  </div>
                </div>
              </div>

              <div className="roe-card-body col-sm-8 pt-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col>
                      <Label>
                        <p style={{ color: "red" }}> {errorMessage}</p>
                      </Label>
                      <Label>
                        <IntlMessages id={`setting.common.adresse.postal`} />
                      </Label>
                      <Row>
                        <Col className="col-sm-2">
                          <FormGroup>
                            <IntlMessages id="setting.organization.contacts.adresse.num" />
                            <AdaInputText
                              name="number"
                              errors={errors}
                              innerRef={register}

                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <IntlMessages id="setting.organization.contacts.addresse" />
                            <AdaInputText
                              name="street"
                              errors={errors}
                              innerRef={register}

                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <IntlMessages id="setting.organization.contacts.ville" />
                            <AdaInputText
                              name="city"
                              errors={errors}
                              innerRef={register}

                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm-2">
                          <FormGroup>
                            <IntlMessages id="setting.organization.contacts.cp" />
                            <AdaInputText
                              name="zipCode"
                              errors={errors}
                              innerRef={register}

                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <IntlMessages id="setting.organization.contacts.region" />
                            <AdaInputText
                              name="provence"
                              errors={errors}
                              innerRef={register}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <IntlMessages id="setting.organization.contacts.pays" />
                            <AdaInputText
                              name="country"
                              errors={errors}
                              innerRef={register}
                            />
                          </FormGroup>
                        </Col>
                      </Row>



                      <FormGroup>
                        <AdaButton type="submit" className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                          <IntlMessages id="action.common.suivant.information.fiscal"/>
                        </AdaButton>

                        <AdaButton className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2" onClick={() => props.step("1")}>
                          <IntlMessages id="action.common.precedent.information"/>
                        </AdaButton>

                        <AdaButton
                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                            onClick={props.cancelAction}
                        >
                          <IntlMessages id="action.common.cancel"/>
                        </AdaButton>


                      </FormGroup>

                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
