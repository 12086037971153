import React from "react";
import { FormGroup, Label, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import { AdaButton, AdaInputText } from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import {buildingEnterprise, infoFisc} from "../../../../helpers/constants";

export default function OrganizationInformationFiscale(props) {
  const { register, handleSubmit, errors } = useForm();
  const [errorMessage, setErrorMessage] = React.useState("");
  const onSubmit = data => {
    console.log("in information fiscale step to adresse : ", data);
    props.updateAction({ administrationInformation: data });
    props.step("4");
  };

  return (
    <div>
      <div>
        <div className="row ma-0">
          <div className="col-sm-12 ptb-15">
            <div className="roe-card-style col-sm-12 row">
              <div className="roe-card-body col-sm-4">
                <div className="pl-5 pt-5 aligner-wrapper text-center">
                  <img className="pt-5"
                       src={infoFisc}
                       style={{width:"140px"}}
                  />
                  <div style={{marginTop:"10px"}}>
                    <h3 className="card-title fs-20 bold-text">Informations Fiscale</h3>
                  </div>
                </div>
              </div>
              <div className="roe-card-body col-sm-8">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col>
                      <Label>
                        <p style={{ color: "red" }}> {errorMessage}</p>
                      </Label>

                      <FormGroup>
                        <IntlMessages id="setting.organization.siret" />
                        <AdaInputText
                          name="siret"
                          errors={errors}
                          innerRef={register}
                        />
                      </FormGroup>
                      <FormGroup>
                        <IntlMessages id="setting.organization.intra.tva" />
                        <AdaInputText
                          name="intraCommunityTva"
                          errors={errors}
                          innerRef={register}
                        />
                      </FormGroup>
                      <FormGroup>
                        <IntlMessages id="setting.organization.registration.number" />
                        <AdaInputText
                          name="registrationNumber"
                          errors={errors}
                          innerRef={register}
                        />
                      </FormGroup>
                      <FormGroup>
                        <IntlMessages id="setting.organization.rcs" />
                        <AdaInputText
                          name="rcs"
                          errors={errors}
                          innerRef={register}
                        />
                      </FormGroup>


                      <FormGroup>
                        <AdaButton type="submit" className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                          <IntlMessages id="action.common.suivant.entities"/>
                        </AdaButton>

                        <AdaButton className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2" onClick={() => props.step("2")}>
                          <IntlMessages id="action.common.precedent.adress.postal"/>
                        </AdaButton>

                        <AdaButton
                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                            onClick={props.cancelAction}
                        >
                          <IntlMessages id="action.common.cancel"/>
                        </AdaButton>


                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
