import React, { useEffect, useState } from "react";
import { FormGroup, Label, Row, CustomInput, Table, Input } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import { AdaButton, AdaInputText} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import {buildingEnterprise} from "../../../../helpers/constants";

const context = "entities";
export default function OrganizationEntities(props) {
  const { register, handleSubmit, errors, setValue, reset } = useForm();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [listEntities, setListEntities] = React.useState([]);
  const [listSelectEntities, setListSelectEntities] = React.useState([]);
  const [idEntity, setIdEntity] = useState(0);

  const onSubmit = data => {
    console.log("on submit data entities", data);
    setIdEntity(prevIdEntity => prevIdEntity + 1);
    let entityObj = {
      idEntity: idEntity,
      name: data.name,
      actif: data.actif,
      owner: "admin",
      contacts: []
    };
    setListEntities(listEntities.concat(entityObj));
    props.initListEntities(entityObj);
    reset();
  };

  const nextStep = () => {
    props.initListEntities(
      listEntities.map(object => ({
        key: object.idEntity,
        label: object.name,
        value: object.name,
        object: object
      }))
    );
    props.updateAction({ entities: listEntities });
    props.step("5");
  };

  useEffect(
    () => {
      console.log("update list Entities");
    },
    [listEntities]
  );

  useEffect(() => {
    register({ name: "name" }, { required: "Ce champ est obligatoire." });
  }, []);

  const deleteElementFromList = (key, list) => {
    console.log("key", key);
    let arr = [];
    list.forEach((value, keyLoop) => {
      if (key !== keyLoop) {
        arr.push(value);
      }
    });
    return arr;
  };

  return (
    <div>
      <div>
        <div className="row ma-0">
          <div className="col-sm-12 ptb-15">
            <div className="roe-card-style col-sm-12 row">
              <div className="roe-card-body col-sm-4">
                <div className="pl-5 pt-5 aligner-wrapper text-center">
                  <img className="pt-5"
                       src={buildingEnterprise}
                       style={{width:"140px"}}
                  />
                  <div style={{marginTop:"10px"}}>
                    <h3 className="card-title fs-20 bold-text">Entitées</h3>
                  </div>
                </div>
              </div>
              <div className="roe-card-body col-sm-8">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col>
                      <Label>
                        <p style={{ color: "red" }}> {errorMessage}</p>
                      </Label>

                      <FormGroup>
                        <div >
                          <Row>
                            <Col
                              className="col-sm-2"
                              style={{ "margin-top": "20px" }}
                            >
                              <FormGroup>
                                <CustomInput
                                  type="switch"
                                  id="actifContactClt"
                                  name="actif"
                                  defaultChecked={true}
                                  innerRef={register}
                                  label={
                                    <IntlMessages id="setting.organization.statut" />
                                  }
                                />
                              </FormGroup>
                            </Col>

                            <Col>
                              <FormGroup>
                                <IntlMessages id="setting.organization.designation" />
                                <AdaInputText
                                  name="name"
                                  errors={errors}
                                  innerRef={register}
                                />
                              </FormGroup>
                            </Col>

                            <Col>
                              <FormGroup>
                                <AdaButton
                                  type="submit"
                                  style={{ marginTop: 18 }}  className="no-border c-outline-AdaSubmit c-btn-sm c-btn-wide ml-sm-2"
                                >
                                  <IntlMessages id="setting.common.add.entity" />
                                </AdaButton>{" "}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div style={{minHeight:"200px"}}>
                        {listEntities.length > 0 && (
                          <Table striped>
                            <thead>
                              <tr>
                                <th />
                                <th />
                                <th />
                              </tr>
                            </thead>

                            <tbody>
                              {listEntities.map((entity, key) => (
                                <tr>
                                  <th scope="row" />
                                  <td>
                                    <AdaButton
                                      className="c-danger"
                                      onClick={() =>
                                        setListEntities(
                                          deleteElementFromList(
                                            key,
                                            listEntities
                                          )
                                        )
                                      }
                                    >
                                      <i className="fas fa-trash" />
                                    </AdaButton>
                                  </td>
                                  <td>{entity.name}</td>
                                  <td>
                                    {entity.actif === true
                                      ? "Actif"
                                      : "Non Actif"}{" "}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                        </div>
                      </FormGroup>




                    </Col>
                  </Row>
                  <FormGroup>
                    <AdaButton onClick={nextStep} className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                      <IntlMessages id="action.common.suivant.contact"/>
                    </AdaButton>

                    <AdaButton className="no-border c-outline-AdaBack c-btn-sm c-btn-wide float-sm-right ml-sm-2" onClick={() => props.step("3")}>
                      <IntlMessages id="action.common.precedent.information.fiscal"/>
                    </AdaButton>

                    <AdaButton
                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                        onClick={props.cancelAction}
                    >
                      <IntlMessages id="action.common.cancel"/>
                    </AdaButton>


                  </FormGroup>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
