import React, { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Col } from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import IntlMessages from "../../../../helpers/intlMessages";
import GenericAddNote from "../../../commun/genericAddNote";
import AddressDetails from "./addressDetails";
import FiscalDetails from "./fiscaleDetails";
import EntitiesDetails from "./entitiesDetails";
import NotesDetails from "./notesDetails";
import BanksDetails from "./bankDetails";
import SweetAlert from "react-bootstrap-sweetalert";
import DisplayInformation from "../../../commun/displayInformation";
import {getConfigObject} from "../../../../helpers/cookie";
import {getHostByAppName, ORGANIZATION_NAME} from "../../../../service/host";
import * as service from "../../../../service/crud.service";
import {addressPostal, buildingEnterprise, infoban, infoFisc} from "../../../../helpers/constants";
import LatestActivity from "../adaOrganizationUpdate/UserInfoDoughnutWidget";
import DisplayInformationStyle from "../adaOrganizationUpdate/displayInformationStyle";
import WrapperInformation from "../adaOrganizationUpdate/wrapper";

export default function OrganizationDetails({ hideDetails, selectedElement }) {
  const [activeTab, setActiveTab] = useState("1");
  const [organization, setOrganization] = React.useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [modalSuccessShow, setModalSuccessShow] = useState(false);
  const [displayInformationList, setDisplayInformationList] = useState([]);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(
    () => {
      console.log("Hide organization details :", hideDetails);
      if (!hideDetails) {
        let idSelectedElement = "";
        if (selectedElement !== null && selectedElement !== undefined) {
          idSelectedElement = selectedElement.id;
          console.log("organization Id for details :", idSelectedElement);

            service.getById(getHostByAppName(ORGANIZATION_NAME),"organizations", callBackRes, (error)=> console.log(error), idSelectedElement);

        }
      }
    },
    [hideDetails]
  );


  const callBackRes = data =>{
      console.log("getOrganization by ID rest service http : ",data);
      setOrganization(data);
      let displayList = [];
      displayList.push({
          label: "setting.organization.designation",
          value:data.name
      });
      displayList.push({
          label: "setting.common.description",
          value:data.description
      });
      displayList.push({
          label: "setting.organization.web",
          value: <a href="" target="_blank">{data.web}</a>
      });
      displayList.push({
          label: "setting.common.telephone",
          value:
             data.contactInformation !== undefined
                  ?data.contactInformation.phone
                  : ""
      });
      displayList.push({
          label: "setting.organization.entity",
          value:
             data.entity !== undefined
                  ?data.entity.name
                  : ""
      });
      displayList.push({
          label: "setting.organization.actif",
          value:data.actif === true ? "Actif" : "Désactiver"
      });

      displayList.push({
          label: "setting.organization.mobile",
          value:
             data.contactInformation !== undefined
                  ?data.contactInformation.mobile
                  : ""
      });
      displayList.push({
          label: "setting.organization.email",
          value:
             data.contactInformation !== undefined
                  ?data.contactInformation.email
                  : ""
      });
      displayList.push({
          label: "setting.organization.fax",
          value:
             data.contactInformation !== undefined
                  ?data.contactInformation.fax
                  : ""
      });

      setDisplayInformationList(displayList);
  }

  React.useEffect(() => {}, [modalSuccessShow]);

  const handlerSaveNoteAction = data => {
    console.log("data note : ", data);
    let tab = [];
    tab.push({ subject: data.subject, text: data.text });
    if (organization.notes !== undefined && organization.notes.length > 0) {
      console.log("organization note not empty : ", organization.notes);
        organization.notes.forEach(note => tab.push(note));
    }
    console.log("data note tab: ", {
      notes: tab
    });
    axios
      .put(`${process.env.REACT_APP_API_URL}/organization-service/organizations/part/${organization.id}`, {
        notes: tab
      },
          getConfigObject())
      .then(res => {
        console.log("getOrganization after update rest service http : ",data);
        setOrganization(res.data);
        setModalShow(false);
        setModalSuccessShow(true);
      });
  };

  return (
    !hideDetails && (
      <div>
        <Nav tabs>
          <NavItem className="roe-card-body">
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              <IntlMessages id={`setting.common.information`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              <IntlMessages id={`setting.organization.contacts.addresse`} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              <IntlMessages id={`setting.common.informations.fiscale`} />
            </NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink
              className={classnames({ active: activeTab === "4" })}
              onClick={() => {
                toggle("4");
              }}
            >
              <IntlMessages id={`action.common.entities`} />
            </NavLink>
          </NavItem>*/}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "5" })}
              onClick={() => {
                toggle("5");
              }}
            >
              <IntlMessages id={`setting.common.cord.bancaires`} />
            </NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink
              className={classnames({ active: activeTab === "6" })}
              onClick={() => {
                toggle("6");
              }}
            >
              <IntlMessages id={`setting.common.notes`} />
            </NavLink>
          </NavItem>*/}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
              <LatestActivity
                  icon={organization.logo ? organization.logo : buildingEnterprise}
                  title={organization.name}
                  description={organization.description ? organization.description : "ND"}
                  site={organization.web ? organization.web : "ND"}
                  telephone={organization.contactInformation && organization.contactInformation.phone ? organization.contactInformation.phone : "ND"}
                  email={organization.contactInformation && organization.contactInformation.email ? organization.contactInformation.email : "ND"}
                  fax={organization.contactInformation && organization.contactInformation.fax ? organization.contactInformation.fax : "ND"}
                  statut={organization.enable === true ? "Activé" : "Désactivé"}
                  owner={organization.owner}
                  activityArea={organization.activityArea}
                  affiliationOrg={organization.affiliate}
              />
          </TabPane>
          <TabPane tabId="2">
            <Col sm="15">
              {organization.id && <WrapperInformation title={"Adresse postale"}
                                                                     icon={addressPostal}
                                                                     informationLigne1={(organization.address.number + " " + organization.address.street)}
                                                                     informationLigne2={organization.address.zipCode + " " + organization.address.city + ", " +organization.address.provence}
                                                                     informationLigne3={organization.address.country}/>
                }
            </Col>
          </TabPane>
          <TabPane tabId="3">
            <Col sm="15">
                <DisplayInformationStyle  icon={infoFisc}
                                          title={"Coordonnées fiscales"}
                                          inf1={{title : "Code RCS", value : organization.administrationInformation && organization.administrationInformation.rcs ? organization.administrationInformation.rcs : "ND"}}
                                          inf4={{title : "Siret", value : organization.administrationInformation && organization.administrationInformation.siret ? organization.administrationInformation.siret : "ND"}}
                                          inf2={{title : "Numéro d'enregistrement", value : organization.administrationInformation && organization.administrationInformation.registrationNumber ? organization.administrationInformation.registrationNumber : "ND"}}
                                          inf5={{title : "Tva intracommunautaire", value : organization.administrationInformation && organization.administrationInformation.intraCommunityTva ? organization.administrationInformation.intraCommunityTva : "ND"}}
                />
            </Col>
          </TabPane>
          {/*<TabPane tabId="4">
            <Col sm="15">
              <EntitiesDetails organization={organization} />
            </Col>
          </TabPane>*/}
          <TabPane tabId="5">
            <Col sm="15">
                {organization && organization.id && <DisplayInformationStyle  icon={infoban}
                                                      title={"Coordonnées bancaires"}
                                                      inf1={{title : "Titulaire", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].owner ? organization.bankDetails[0].owner : "ND"}}
                                                      inf4={{title : "Banque", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].bank ? organization.bankDetails[0].bank : "ND"}}
                                                      inf2={{title : "RIB", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].rib ? organization.bankDetails[0].rib : "ND"}}
                                                      inf5={{title : "BIC", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].bic ? organization.bankDetails[0].bic : "ND"}}
                                                      inf3={{title : "IBAN", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].iban ? organization.bankDetails[0].iban : "ND"}}
                />}
            </Col>
          </TabPane>
          {/*<TabPane tabId="6">
            <NotesDetails organization={organization} onClick={() => setModalShow(true)} />
          </TabPane>*/}
        </TabContent>
        <GenericAddNote
          show={modalShow}
          onHide={() => setModalShow(false)}
          processAction={handlerSaveNoteAction}
        />
        <SweetAlert
          success
          show={modalSuccessShow}
          title="Success!"
          onConfirm={() => setModalSuccessShow(false)}
          confirmBtnCssClass="sweet-alert-confirm-button"
          cancelBtnCssClass="sweet-alert-cancle-button"
        >
          <IntlMessages id="action.common.succes" />
        </SweetAlert>
      </div>
    )
  );
}
