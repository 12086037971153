import Organizations from "../views/setting/organization/organizations"
import Etablissements from "../views/setting/etablissement/etablissements"

import OrganizationForm from "../views/setting/organization/newOrganization/newOrganization"
import AdaOrganizationUpdate from "../views/setting/organization/adaOrganizationUpdate/AdaOrganizationUpdate";
import Maintenance from "../views/pages/error/maintenance";
import EtablissementForm from "../views/setting/etablissement/newEtablissement/newEtablissement";
const settingRoutes = [
    {path: "/organizations", component: Organizations},
    {path: "/organizations/new", component: OrganizationForm},
    {path: "/organizations/:organizationId/edit", component: AdaOrganizationUpdate},

    {path: "/etablissements", component: Etablissements},
    {path: "/etablissements/new", component: EtablissementForm},
    {path: "/etablissements/:etablissements/edit", component: EtablissementForm},

    {path: "/maintenance", component: Maintenance}
];

export default settingRoutes;