import React, {useEffect, useState} from "react";
import { Col, CustomInput, Row, FormGroup } from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import { AdaSelectBuilder } from "@adaming/ada-react-business-component";
import { getHostByAppName, NOMENCLATURE_NAME } from "../../../../service/host";
import {Controller} from "react-hook-form";
import * as service from "../../../../service/crud.service";
import UserInfoWrapper from "./userinfo.style";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

export default function UpdateAddressInformation({icon, title, register, errors, address}){

    useEffect(()=>{

    },[])

    return (
        <div>
            <PageviewsChartWrapper>
                <div className="page-view-chart-widget-card">

                    <div className="analytics mt-4">
                        <UserInfoWrapper className="fill-height">
                            <div className="roe-shadow-2">

                                <div className="row mb-5">
                            <div className="col-md-4 aligner-wrapper text-center">
                                <img
                                    src={icon}
                                    style={{width:"140px"}}
                                />
                                <div style={{marginTop:"10px"}}>
                                    <h3 className="card-title fs-18 bold-text">{title}</h3>
                                </div>
                            </div>
                            <div className="col-md-4 legend-wrapper">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                        <AdaInputText
                                            type="number"
                                            name={"address.number"}
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.number}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">N°</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <AdaInputText
                                            name={"address.street"}
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.street}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Adresse</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                        <AdaInputText
                                            name={"address.city"}
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.city}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Ville</small>
                                </div>
                            </div>

                            <div className="col-md-4 legend-wrapper">
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                        <AdaInputText
                                            name={"address.zipCode"}
                                            errors={errors}
                                            defaultValue={address.zipCode}
                                            innerRef={register}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Code postal</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <AdaInputText
                                            name={"address.provence"}
                                            innerRef={register}
                                            errors={errors}
                                            defaultValue={address.provence}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Région</small>
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <div className="dot-indicator bg-success mt-1 mr-2"></div>
                                        <AdaInputText
                                            id={"countryId"}
                                            name={"address.country"}
                                            errors={errors}
                                            defaultValue={address.country}
                                            innerRef={register}
                                        />
                                    </div>
                                    <small className="text-muted ml-3">Pays</small>
                                </div>
                            </div>

                                </div>
                            </div>
                        </UserInfoWrapper>
                    </div>
                </div>
            </PageviewsChartWrapper>
        </div>
    );
}
