import React from "react";
import Modal from "react-bootstrap/Modal";
import { AdaButton, AdaInputText } from "@adaming/ada-react-component";
import { FormGroup, Input, Row } from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../helpers/intlMessages";
import {useForm} from "react-hook-form";

export default function GenericAddNote(props) {
  const {
    register,
    handleSubmit,
    errors
  } = useForm();

  const onSubmit = data => {
    props.processAction(data);
  };


  return <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Ajouter une note
        </Modal.Title>
      </Modal.Header>
      <div>
        <div>
          <div className="row ma-0">
            <div className="col-sm-12 ptb-15">
              <div className="roe-card-style">
                <div className="roe-card-body col-sm-8">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col>
                        <FormGroup>
                          <IntlMessages id="setting.common.subject" />

                          <AdaInputText name="subject" errors={errors} innerRef={register(
                              { required: true }
                            )} />
                        </FormGroup>
                        <FormGroup>
                          <IntlMessages id="setting.common.text" />
                          <Input type="textarea" name="text" errors={errors} innerRef={register(
                              { required: true }
                            )} />
                        </FormGroup>
                        <FormGroup>
                          <AdaButton onClick={props.onHide}>
                            <IntlMessages id="action.common.cancel" />
                          </AdaButton>
                          <AdaButton type="submit">
                            <IntlMessages id="action.common.submit" />
                          </AdaButton>
                        </FormGroup>
                      </Col>
                    </Row>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal.Footer />
    </Modal>;
}
