import React, {Fragment, useEffect, useState} from "react";
import "./AdaOrganizationUpdate.scss";
import Accordion from "./Accordion";
import {
    CustomInput,
    Row,
    Table,
    Col,
    Collapse
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import {AdaInputText, AdaButton, AdaSelect} from "@adaming/ada-react-component";
import axios from "axios";

import GenericSuccessModal from "../../../commun/genericSuccesModal";
import {useHistory} from "react-router";
import GenericAddNote from "../../../commun/genericAddNote";
import FormGroup from "react-bootstrap/FormGroup";
import AdaUpdateBankDetails from "./AdaUpdateBankDetails";
import {getConfigObject} from "../../../../helpers/cookie";
import * as service from "../../../../service/crud.service";
import {getHostByAppName, ORGANIZATION_NAME} from "../../../../service/host";
import LatestActivity from "./UserInfoDoughnutWidget";
import {addressPostal, buildingEnterprise, infoban, infoFisc} from "../../../../helpers/constants";
import UpdateInformation from "./updateInformation";
import DisplayInformationStyle from "./displayInformationStyle";
import UpdateFiscalStyle from "./updateFiscalInformation";
import WrapperInformation from "./wrapper";
import UpdateAddressInformation from "./updateAdresse";
import UpdateBankInformation from "./updateBankInformation";

const AdaOrganizationUpdate = (props) => {
    const [organization, setOrganization] = useState({});
    const [modal, setModal] = useState(false);

    const organizationId = initorganizationId();

    const [status, setStatus] = useState("moins");
    const [collapse, setCollapse] = useState(false);
    const [collapse1, setCollapse1] = useState(false);
    /*Edit mode */
    const [editInformation, setEditInformation] = useState(false);
    const [editIF, setEditIF] = useState(false);
    const [editadrs, setEditadrs] = useState(false);
    const [editBK, setEditBK] = useState(false);

    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Register form */
    const {register, handleSubmit, errors, setValue, control} = useForm();
    const [entityList, setEntityList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [selectedObject, setSelectedObject] = useState({})

    const [entity, setEntity] = useState({});
    const [isLoading, setIsLoading] = React.useState(false);

    const [updateObjectBankDetails, setUpdateObjectBankDetails] = useState({})

    let history = props.history;

    function initorganizationId() {
        const {
            match: {params}
        } = props;
        return params.organizationId;
    }

    /*const handlerSaveNoteAction = data => {
        console.log("data note : ", data);
        let tab = [];
        tab.push({subject: data.subject, text: data.text});
        if (organization.notes !== undefined && organization.notes.length > 0) {
            console.log("client note not empty : ", organization.notes);
            organization.notes.forEach(note => tab.push(note));
        }
        console.log("data note tab : ", {
            notes: tab
        });
        axios
            .patch(`${process.env.REACT_APP_API_URL}/organization-service/organizations/${organization.id}`, {
                notes: tab
            },
                getConfigObject())
            .then(res => {
                console.log("getOrganization after update rest service http : ", res.data);
                setOrganization(res.data);
                setModalShow(false);
                setModalSuccessShow(true);
            });
    };*/


    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            service.getById(getHostByAppName(ORGANIZATION_NAME),"organizations", setOrganization, (error)=> console.log(error), organizationId);
            setIsLoading(false);
        };
        fetchData();
    }, []);


    useEffect(() => {
        if (organization.id !== undefined) {
            /*axios.get(`${process.env.REACT_APP_API_URL}/organization-service/entity`,
                getConfigObject()).then(res => {
                console.log("get List Entities rest service http : ", res.data);
                if (res.data !== undefined) {
                    res.data.forEach(item =>
                        entityList.push({
                            key: item.id,
                            label: item.name,
                            value: item.id,
                            object: item
                        })
                    );
                }
            });*/
            if (organization.entity !== undefined) {
                setEntity({
                    key: organization.entity.id,
                    label: organization.entity.name,
                    value: organization.entity.id,
                    object: organization.entity
                });
                setValue("entity", organization.entity);
            }
        }

    }, [organization]);


    const callBackSave = res => {
        setOrganization(res.data);
        setEditInformation(false);
        setEditIF(false);
        setEditadrs(false);
        setEditBK(false);
    }

    const saveEdit = data => {
        service.patch(getHostByAppName(ORGANIZATION_NAME),`organizations`,callBackSave,(error)=>setErrorMessage(error.toString()),{id:organizationId,...data})
    }


    const onSubmitInformation = data => {
        console.log("hi Rostom : ", data);
        setEditInformation(false);
        console.log("call update rest service for organizationUpdate :", JSON.stringify(data));
        saveEdit(
            {...data, ...selectedObject}
        )
    };


    const onSubmitInformationFiscal = data => {
        console.log("hi testa : ", data);
        setEditIF(false);
        console.log("call update for Fiscal Administration information", JSON.stringify(data));
        saveEdit(
            data
        )
    };

    const onSubmitAdresse = data => {
        console.log("hi Rostom : ", data);
        setEditadrs(false);
        console.log("call update for Address charges ", JSON.stringify(data))
        saveEdit(
            data
        )
    };

    const onSubmitBankDetails = data => {
        console.log("hi Rostom : ", data);
        setEditBK(false);
        console.log("call update for bank charges ", JSON.stringify(data))
        saveEdit(
            {bankDetails: [{...data.bankDetails}]}
        )
    };

    const handleChangeEntity = selectedOption => {
        console.log("entity", selectedOption);
        if (selectedOption !== null) {
            setValue("entity", selectedOption.object);
        } else {
            setValue("entity", null);
        }
    };

    const handlerEditBankDetails = (bankDetail, key) => {
        console.log("edit bank details", bankDetail, key)
        setEditBK(true)
        setUpdateObjectBankDetails({...bankDetail, id: key})
    }

    const dispatchSelectObj = data =>{
        setSelectedObject({...selectedObject, ...data})
    }

    return (
        <Fragment>
            {isLoading ? (
                <div>Loading ...</div>
            ) : (
                <div>
                    <div className="row ma-0">

                        <div className="col-lg-12 ptb-10">
                            <div className="roe-card-style">
                                <div className="roe-card-header">
                                </div>

                                <div className="roe-card-body">
                                    <div >
                                        {!editInformation &&
                                        <LatestActivity
                                            icon={organization.logo ? organization.logo : buildingEnterprise}
                                            title={organization.name}
                                            description={organization.description ? organization.description : "ND"}
                                            site={organization.web ? organization.web : "ND"}
                                            telephone={organization.contactInformation && organization.contactInformation.phone ? organization.contactInformation.phone : "ND"}
                                            email={organization.contactInformation && organization.contactInformation.email ? organization.contactInformation.email : "ND"}
                                            fax={organization.contactInformation && organization.contactInformation.fax ? organization.contactInformation.fax : "ND"}
                                            statut={organization.enable === true ? "Activé" : "Désactivé"}
                                            owner={organization.owner}
                                            activityArea={organization.activityArea}
                                            affiliationOrg={organization.affiliate}
                                            organization={organization}
                                            saveEdit={saveEdit}
                                            showUploadLogo={true}
                                        />
                                        }

                                        <form onSubmit={handleSubmit(onSubmitInformation)}>
                                            {editInformation && organization.id && <UpdateInformation icon={buildingEnterprise}
                                                                                                title={organization.name} control={control} register={register} errors={errors} setValue={setValue} organization={organization} dispatch={dispatchSelectObj}/>}



                                            <div>

                                                <Row className="mt-3">
                                                    <Col className="col-sm-2 ml-auto">
                                                        {!editInformation && (
                                                            <AdaButton
                                                                className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                onClick={() => {
                                                                    setEditInformation(true);
                                                                    setCollapse(false);

                                                                }}
                                                            >
                                                                <IntlMessages id="action.common.update"/>
                                                            </AdaButton>

                                                        )}

                                                        {editInformation && (
                                                            <FormGroup>
                                                                <AdaButton
                                                                    className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                    type="submit"
                                                                >
                                                                    <IntlMessages id="action.common.submit"/>
                                                                </AdaButton>
                                                                <AdaButton
                                                                    className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                    onClick={() => setEditInformation(false)}
                                                                >
                                                                    <IntlMessages id="action.common.cancel"/>
                                                                </AdaButton>

                                                            </FormGroup>
                                                        )}
                                                    </Col>
                                                </Row>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*Accordion section*/}



                    <div className="col-lg-12 ptb-10">
                        <Accordion edit={editIF} title={<IntlMessages id="setting.common.informations.fiscale"/>}>
                            <br/>
                            <div className="row ma-0">

                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header">

                                        </div>
                                        <div className="roe-card-body">
                                            <div>
                                                {!editIF && <DisplayInformationStyle  icon={infoFisc}
                                                                                      title={"Coordonnées fiscales"}
                                                                                      inf1={{title : "Code RCS", value : organization.administrationInformation && organization.administrationInformation.rcs ? organization.administrationInformation.rcs : "ND"}}
                                                                                      inf4={{title : "Siret", value : organization.administrationInformation && organization.administrationInformation.siret ? organization.administrationInformation.siret : "ND"}}
                                                                                      inf2={{title : "Numéro d'enregistrement", value : organization.administrationInformation && organization.administrationInformation.registrationNumber ? organization.administrationInformation.registrationNumber : "ND"}}
                                                                                      inf5={{title : "Tva intracommunautaire", value : organization.administrationInformation && organization.administrationInformation.intraCommunityTva ? organization.administrationInformation.intraCommunityTva : "ND"}}
                                                />}

                                                <form onSubmit={handleSubmit(onSubmitInformationFiscal)}>
                                                    {editIF && <UpdateFiscalStyle client={organization} errors={errors} register={register} icon={infoFisc}
                                                                                  title={"Coordonnées fiscales"}/>}
                                                    <br/>

                                                    <div>

                                                        <Row className="mt-3">
                                                            <Col className="col-sm-2 ml-auto">
                                                                {!editIF && (
                                                                    <AdaButton
                                                                        className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                        onClick={() => {
                                                                            setEditIF(true);

                                                                        }}
                                                                    >
                                                                        <IntlMessages id="action.common.update"/>
                                                                    </AdaButton>

                                                                )}

                                                                {editIF && (
                                                                    <FormGroup>
                                                                        <AdaButton
                                                                            className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                            type="submit"
                                                                        >
                                                                            <IntlMessages id="action.common.submit"/>
                                                                        </AdaButton>
                                                                        <AdaButton
                                                                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                            onClick={() => setEditIF(false)}
                                                                        >
                                                                            <IntlMessages id="action.common.cancel"/>
                                                                        </AdaButton>

                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </Accordion>
                    </div>
                    <div className="col-lg-12 ptb-10">
                        <Accordion edit={editadrs} title={<IntlMessages id="setting.common.adresse.postal"/>}>
                            <div className="row ma-0">
                                <br/>
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header">

                                        </div>
                                        <div className="roe-card-body">
                                            <div>
                                                {!editadrs && organization.id && <WrapperInformation title={"Adresse postale"}
                                                    icon={addressPostal}
                                                    informationLigne1={(organization.address.number + " " + organization.address.street)}
                                                    informationLigne2={organization.address.zipCode + " " + organization.address.city + ", " +organization.address.provence}
                                                    informationLigne3={organization.address.country}/>
                                                }
                                                <form onSubmit={handleSubmit(onSubmitAdresse)}>

                                                    <div>
                                                        {editadrs && organization.id && <UpdateAddressInformation address={organization.address} errors={errors} register={register} title={"Adresse postale"} icon={addressPostal}/>}
                                                    </div>

                                                    <br/>

                                                    <div>

                                                        <Row className="mt-3">
                                                            <Col className="col-sm-2 ml-auto">
                                                                {!editadrs && (
                                                                    <AdaButton
                                                                        className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                        onClick={() => {
                                                                            setEditadrs(true);

                                                                        }}
                                                                    >
                                                                        <IntlMessages id="action.common.update"/>
                                                                    </AdaButton>

                                                                )}

                                                                {editadrs && (
                                                                    <FormGroup>
                                                                        <AdaButton
                                                                            className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                            type="submit"
                                                                        >
                                                                            <IntlMessages id="action.common.submit"/>
                                                                        </AdaButton>
                                                                        <AdaButton
                                                                            className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                            onClick={() => setEditadrs(false)}
                                                                        >
                                                                            <IntlMessages id="action.common.cancel"/>
                                                                        </AdaButton>

                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Accordion>
                    </div>

                    <div className="col-lg-12 ptb-10">
                        <Accordion edit={editBK} title={<IntlMessages id="setting.common.cord.bancaires"/>}>

                            <div className="row ma-0">
                                <br/>
                                <div className="col-lg-12 ptb-10">
                                    <div className="roe-card-style">
                                        <div className="roe-card-header">

                                        </div>
                                        <div className="roe-card-body">
                                            <div>

                                                {!editBK && <DisplayInformationStyle  icon={infoban}
                                                                                      title={"Coordonnées bancaires"}
                                                                                      inf1={{title : "Titulaire", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].owner ? organization.bankDetails[0].owner : "ND"}}
                                                                                      inf4={{title : "Banque", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].bank ? organization.bankDetails[0].bank : "ND"}}
                                                                                      inf2={{title : "RIB", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].rib ? organization.bankDetails[0].rib : "ND"}}
                                                                                      inf5={{title : "BIC", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].bic ? organization.bankDetails[0].bic : "ND"}}
                                                                                      inf3={{title : "IBAN", value : organization.bankDetails && organization.bankDetails.length > 0 && organization.bankDetails[0].iban ? organization.bankDetails[0].iban : "ND"}}
                                                />}

                                                <form onSubmit={handleSubmit(onSubmitBankDetails)}>

                                                    {editBK && <UpdateBankInformation icon={infoban} title={"Coordonnées bancaires"} register={register} errors={errors}
                                                                                      bankDetails={organization.bankDetails && organization.bankDetails.length >0 ? organization.bankDetails[0] :
                                                                                          {bank: "",owner: "",iban: "",rib: "",bic: ""}}/>}

                                                    <br/>

                                                    <Row className="mt-3">
                                                        <Col className="col-sm-2 ml-auto">
                                                            {!editBK && (
                                                                <AdaButton
                                                                    className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3 "
                                                                    onClick={() => {
                                                                        setEditBK(true);

                                                                    }}
                                                                >
                                                                    <IntlMessages id="action.common.update"/>
                                                                </AdaButton>

                                                            )}

                                                            {editBK && (
                                                                <FormGroup>
                                                                    <AdaButton
                                                                        className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-3"
                                                                        type="submit"
                                                                    >
                                                                        <IntlMessages id="action.common.submit"/>
                                                                    </AdaButton>
                                                                    <AdaButton
                                                                        className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                                        onClick={() => setEditBK(false)}
                                                                    >
                                                                        <IntlMessages id="action.common.cancel"/>
                                                                    </AdaButton>

                                                                </FormGroup>
                                                            )}
                                                        </Col>
                                                    </Row>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>

                    <GenericSuccessModal show={modal} onHide={() => setModal(false)}/>
                </div>)}
        </Fragment>
    );
};

export default AdaOrganizationUpdate;
