import React, {useEffect, useState} from "react";
import Gauge from "react-svg-gauge";
import UserInfoWrapper from "./userinfo.style";
import * as service from "../../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../../service/host";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";
import {AdaButton} from "@adaming/ada-react-component";
import LogoUpload from "../../../commun/logo.upload";

const LatestOrgActivity = ({icon, title, description, site, telephone, statut, owner, activityArea, email, fax, name, affiliationOrg, organization, saveEdit, showUploadLogo=false}) => {

  const [actArea, setActArea] = useState({})
  const [affiliation, setAffiliation] = useState({})




  useEffect(()=>{
    if(activityArea){
      service.getById(
          getHostByAppName(NOMENCLATURE_NAME),
          `company_activity_areas`,
          setActArea,
          error => console.log(error), activityArea
      );
    }
  },[activityArea])

  useEffect(()=>{
    if(affiliationOrg){
      service.getById(
          getHostByAppName(ORGANIZATION_NAME),
          `organizations`,
          setAffiliation,
          error => console.log(error), affiliationOrg
      );
    }
  },[affiliationOrg])

  return (
      <div>
        <PageviewsChartWrapper>
          <div className="page-view-chart-widget-card">
            <div className="header fs-20 demi-bold-text">{name}</div>
            <div className="analytics mt-4">



              <UserInfoWrapper className="fill-height">
                <div className="roe-shadow-2">

                  <div className="row mb-5">
                    <div className="col-md-4 aligner-wrapper text-center">

                      <img
                          src={icon}
                          style={{width:"140px"}}
                      />
                      <div style={{marginTop:"10px"}}>
                        <h4 className="mb-3 text-dark fs-15 bold-text">{title}</h4>
                        {showUploadLogo && <LogoUpload objectToSave={organization} save={saveEdit}/>}
                      </div>
                    </div>
                    <div className="col-md-4 legend-wrapper">

                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text">{affiliation && affiliation.name ? affiliation.name : "ND"}
                          </h4>
                        </div>
                        <small className="text-muted ml-3">Affiliation</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text">{telephone}</h4>
                        </div>
                        <small className="text-muted ml-3">Téléphone</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text">{fax}</h4>
                        </div>
                        <small className="text-muted ml-3">Fax</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-success mt-1 mr-2"></div>
                          <a
                              href={`https://${site}/`}
                              rel="noopener noreferrer"
                              target="_blank">
                            {site}
                          </a>
                        </div>
                        <small className="text-muted ml-3">Site web</small>
                      </div>
                    </div>

                    <div className="col-md-4 legend-wrapper">

                      <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text" >{description}</h4>
                        </div>
                        <small className="text-muted ml-3">Description</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text">{email}</h4>
                        </div>
                        <small className="text-muted ml-3">Email</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-info mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text">{actArea && actArea.name ? actArea.name : "ND"}
                          </h4>
                        </div>
                        <small className="text-muted ml-3">Secteur d'activité</small>
                      </div>
                      <div className="d-flex flex-column justify-content-center border-top py-3 mt-3 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="dot-indicator bg-success mt-1 mr-2"></div>
                          <h4 className="mb-0 text-dark fs-15 bold-text">{statut}</h4>
                        </div>
                        <small className="text-muted ml-3">Statut</small>
                      </div>
                    </div>

                  </div>
                </div>
              </UserInfoWrapper>
            </div>
          </div>
        </PageviewsChartWrapper>
      </div>
  );
};

export default LatestOrgActivity;
