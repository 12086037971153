import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const  columnsEtablissement = [
    {
        name: <IntlMessages
            id="setting.organization.designation"
        />,
        selector: "name",
        sortable: false,
        filtrable: true,
    },{
        name: <IntlMessages
            id="setting.organization.statut"
        />,
        selector: "actif",
        sortable: false,
        filtrable: false,
        format: row => (row.actif === true ? "Actif" : "Non Actif")
    }
];
