import React, {useEffect, useState} from "react";
import OrganizationInformation from "./organizationInformation";
import OrganizationInformationFiscale from "./organizationInformationFiscale";
import OrganizationAdressePostal from "./organizationAdressePostal";
import OrganizationCordBancaires from "./organizationCordBancaires";
import axios from "axios";

import {
    Card,
    CardText,
    CardTitle,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Label
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import OrganizationEntities from "./organizationEntities";
import {getConfigObject} from "../../../../helpers/cookie";
import {getHostByAppName, ORGANIZATION_NAME} from "../../../../service/host";
import * as service from "../../../../service/crud.service";

export default function OrganizationForm(props) {
    const [activeTab, setActiveTab] = useState("1");
    const [organization, setOrganization] = useState({});
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [informationBancaireAffiliation, setInformationBancaireAffiliation] = React.useState([]);
    const [entities, setEntities] = React.useState([]);

    let history = props.history; 

    const step = tab => {
        console.log("in step ", tab);
        if (activeTab !== tab) setActiveTab(tab);
    };

    const cancel = () => {
        history.push("/organizations");
    };

    const setOrganizationInformation = data => {
        setOrganization({...organization, ...data});
        console.log("data in add Organization :", data);
        console.log("Organization :", organization);
    };

    const saveOrganization = object => {
        setLoadingModal(true);
        setErrorMessage("");
        console.log("save organization :");
        service.post(getHostByAppName(ORGANIZATION_NAME),"organizations", callBackRes, callBackError, {...organization, ...object});
    };


    const callBackRes = (data) => {
        console.log(data);
        history.push("/organizations");
    }

    const callBackError = (error) => {
        console.log(error);
        setErrorMessage(error.toString());
        setLoadingModal(false);
    }

    const initListInformationBancaire = affiliationObj => {
        console.log("affiliationObj", affiliationObj)
        if (affiliationObj !== undefined) {
            if (affiliationObj.bankDetails !== undefined) {
                let listAffiliationBank = []
                if (!affiliationObj.bankDetails.isEmpty) {
                    listAffiliationBank = affiliationObj.bankDetails.map(objectAff => ({
                        key: objectAff,
                        label:
                            objectAff.owner +
                            " " +
                            objectAff.bank,
                        value:
                            objectAff.owner +
                            " " +
                            objectAff.bank,
                        object:
                        objectAff
                    }))

                }
                setInformationBancaireAffiliation(listAffiliationBank);
               console.log(" listAffiliationBank", listAffiliationBank)
            }
        } else {
            setInformationBancaireAffiliation([]);
        }
    };

    const initListEntities = entityList => {
        setEntities(entityList);
    };

    useEffect(() => {
    }, [activeTab]);

    return (
        <div>
            <h4>Ajouter une organization : </h4>
            <br/>
            <Label>
                <p style={{color: "red"}}> {errorMessage}</p>
            </Label>
            <Nav tabs>
                <NavItem className="roe-card-body">
                    <NavLink className={classnames({active: activeTab === "1"})}>
                        <IntlMessages id={`setting.common.information`}/>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({active: activeTab === "2"})}>
                        <IntlMessages id={`setting.organization.contacts.addresse`}/>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({active: activeTab === "3"})}>
                        <IntlMessages id={`setting.common.informations.fiscale`}/>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({active: activeTab === "4"})}>
                        <IntlMessages id={`action.common.entities`}/>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classnames({active: activeTab === "5"})}>
                        <IntlMessages id={`setting.common.cord.bancaires`}/>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <Card body>
                                <CardTitle/>
                                <CardText>
                                    <OrganizationInformation
                                        step={step}
                                        cancelAction={cancel}
                                        organizationObj={organization}
                                        updateAction={setOrganizationInformation}
                                        iniInformationBancaire={initListInformationBancaire}
                                    />
                                </CardText>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Col sm="15">
                        <Card body>
                            <CardText>
                                <OrganizationAdressePostal
                                    step={step}
                                    cancelAction={cancel}
                                    organizationObj={organization}
                                    updateAction={setOrganizationInformation}
                                />
                            </CardText>
                        </Card>
                    </Col>
                </TabPane>
                <TabPane tabId="3">
                    <Col sm="15">
                        <Card body>
                            <CardText>
                                <OrganizationInformationFiscale
                                    step={step}
                                    cancelAction={cancel}
                                    organizationObj={organization}
                                    updateAction={setOrganizationInformation}
                                />
                            </CardText>
                        </Card>
                    </Col>
                </TabPane>
                <TabPane tabId="4">
                    <Col sm="15">
                        <Card body>
                            <CardText>
                                <OrganizationEntities
                                    step={step}
                                    cancelAction={cancel}
                                    organizationObj={organization}
                                    initListEntities={initListEntities}
                                    updateAction={setOrganizationInformation}
                                />
                            </CardText>
                        </Card>
                    </Col>
                </TabPane>
                <TabPane tabId="5">
                    <Col sm="15">
                        <Card body>
                            <CardText>
                                <OrganizationCordBancaires
                                    step={step}
                                    cancelAction={cancel}
                                    organizationObj={organization}
                                    updateAction={setOrganizationInformation}
                                    saveOrganization={saveOrganization}
                                    listEntities={entities}
                                    informationBancaireAffiliation={informationBancaireAffiliation}
                                />
                            </CardText>
                        </Card>
                    </Col>
                </TabPane>
            </TabContent>
        </div>
    );
}
