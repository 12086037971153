import React, {useEffect, useState} from "react";

import {
    Col,
    Row,
    Label, FormGroup
} from "reactstrap";
import IntlMessages from "../../../../helpers/intlMessages";
import {useHistory} from "react-router";
import {getHostByAppName, ORGANIZATION_NAME} from "../../../../service/host";
import * as service from "../../../../service/crud.service";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import {getAllForSelect} from "../../../../service/crud.service";
import {getByIdMethod} from "../../../../service/crud.service";

const context = "etablissements";
export default function EtablissementForm(props) {
    const id = props.location.pathname.split("/")[2];
    const [etablissement, setEtablissement] = useState({});
    const [organizationList, setOrganizationList] = React.useState([]);
    const [selectedOrganizaton, setSelectedOrganizaton] = React.useState();

    const [administrationInformation, setAdministrationInformation] = React.useState({});
    const [address, setAddress] = React.useState({});

    const [loadingModal, setLoadingModal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const {register, handleSubmit, errors, setValue} = useForm();

    let history = props.history;

    useEffect(() => {
        getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations/generic`, setOrganizationList)
    }, []);

    React.useEffect(() => {
        if (id !== undefined) {
            getByIdMethod(getHostByAppName(ORGANIZATION_NAME), context, setEtablissement, init, setErrorMessage, id)
        }
    }, [id]);

    function init(data) {
        console.log(data);
        if (data && data.org) {
            setSelectedOrganizaton(
                {
                    key: data.org.id,
                    label: data.org.name,
                    value: data.org.id,
                    object: data.org
                });
            setValue("org", data.org);
        }
        if(data.administrationInformation){
            console.log(data.administrationInformation);
            setAdministrationInformation(data.administrationInformation)
        }
        if(data.address){
            setAddress(data.address)
        }
    }

    const cancel = () => {
        history.push("/etablissements");
    };

    const onSubmit = data => {
        console.log(data);
        data.actif = true;
        data.org = selectedOrganizaton.object;
        if (etablissement.id !== undefined) {
            data.id = etablissement.id;
            service.put(`${process.env.REACT_APP_API_URL}/organization-service`, context, () => history.push("/" + context), () => {
            }, data)
        } else {
            service.post(`${process.env.REACT_APP_API_URL}/organization-service`, context, () => history.push("/" + context), () => {
            }, data)
        }
    };

    const callBackRes = (data) => {
        console.log(data);
        history.push("/organizations");
    };

    const callBackError = (error) => {
        console.log(error);
        setErrorMessage(error.toString());
        setLoadingModal(false);
    };

    const handleOrganization = selectedOption => {
        etablissement.org = selectedOption.object;
        setSelectedOrganizaton(selectedOption);
    };
    return (
        <div>
            <Label>
                <p style={{color: "red"}}> {errorMessage}</p>
            </Label>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style col-sm-12 row">
                        <div className="roe-card-body col-sm-12">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="roe-card-body col-sm-12 pt-3">
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>

                                            <FormGroup>
                                                <IntlMessages id="setting.etablissement.siret"/>
                                                <AdaInputText
                                                    name="name"
                                                    defaultValue={etablissement.name}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                        </Col>
                                        <Col className="col-sm-6">
                                            <FormGroup>
                                                Organisation
                                                <AdaSelect
                                                    id="organizationList"
                                                    name="organizationList"
                                                    value={selectedOrganizaton}
                                                    options={organizationList}
                                                    onChange={handleOrganization}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{marginTop: "5px"}}>
                                            <h2 className="card-title fs-15 bold-text">Informations Fiscale</h2>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.siret"/>
                                                <AdaInputText
                                                    name="administrationInformation.siret"
                                                    defaultValue={administrationInformation.siret}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.intra.tva"/>
                                                <AdaInputText
                                                    name="administrationInformation.vatNumberIntracommunity"
                                                    defaultValue={administrationInformation.vatNumberIntracommunity}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.registration.number"/>
                                                <AdaInputText
                                                    name="administrationInformation.registrationNumber"
                                                    defaultValue={administrationInformation.registrationNumber}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.rcs"/>
                                                <AdaInputText
                                                    name="administrationInformation.rcs"
                                                    defaultValue={administrationInformation.rcs}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{marginTop: "5px"}}>
                                            <h2 className="card-title fs-15 bold-text">Adresse postale</h2>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col className="col-sm-2">
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.adresse.num"/>
                                                <AdaInputText
                                                    name="address.number"
                                                    defaultValue={address.number}
                                                    errors={errors}
                                                    innerRef={register}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.addresse"/>
                                                <AdaInputText
                                                    name="address.street"
                                                    defaultValue={address.street}
                                                    errors={errors}
                                                    innerRef={register}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.ville"/>
                                                <AdaInputText
                                                    name="address.city"
                                                    defaultValue={address.city}
                                                    errors={errors}
                                                    innerRef={register}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-sm-2">
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.cp"/>
                                                <AdaInputText
                                                    name="address.zipCode"
                                                    defaultValue={address.zipCode}
                                                    errors={errors}
                                                    innerRef={register}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.region"/>
                                                <AdaInputText
                                                    name="address.provence"
                                                    defaultValue={address.provence}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.contacts.pays"/>
                                                <AdaInputText
                                                    name="address.country"
                                                    defaultValue={address.country}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col>

                                        <FormGroup>
                                            <AdaButton type="submit"
                                                       className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right">
                                                Valider
                                            </AdaButton>

                                            <AdaButton
                                                className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right"
                                                onClick={cancel}
                                            >
                                                <IntlMessages id="action.common.cancel"/>
                                            </AdaButton>

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
