import React, {useEffect, useState} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {FormGroup, Label, Row, CustomInput} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import axios from "axios";
import {getConfigObject} from "../../../../helpers/cookie";
import {getHostByAppName, NOMENCLATURE_NAME, ORGANIZATION_NAME} from "../../../../service/host";
import * as service from "../../../../service/crud.service";
import {buildingEnterprise} from "../../../../helpers/constants";
import LogoUpload from "../../../commun/logo.upload";

export default function OrganizationInformation(props) {
    const {register, handleSubmit, errors, setValue} = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [entityList, setEntityList] = useState([]);
    const [affiliationList, setAffiliationList] = useState([]);
    const [activityAreaList, setActivityAreaList] = useState([]);
    const [logo, setLogo] = useState("")

    const onSubmit = data => {
        console.log("in information step to Information bancaire : ", data);
        props.updateAction({
            name: data.name,
            web: data.web,
            description: data.description,
            actif: data.actif,
            logo : logo ? logo : null,
            affiliate: data.affiliate !== undefined ? data.affiliate : null,
            activityArea: data.activityArea !== undefined ? data.activityArea : null,
            owner: "admin",
            contactInformation: {
                email: data.email,
                phone: data.phone,
                mobile: data.mobile,
                fax: data.fax
            }
        });
        props.step("2");
    };

    const handleChangeAffiliation = selectedOption => {
        console.log("Affiliate", selectedOption);
        if (selectedOption !== null) {
            setValue("affiliate", selectedOption.value);
            props.iniInformationBancaire(selectedOption.object);
        } else {
            setValue("affiliate", undefined);
            props.iniInformationBancaire(undefined);
        }
    };


    const handleChangeActivityArea = selectedOption => {
        console.log("activityArea", selectedOption);
        if (selectedOption !== null) {
            setValue("activityArea", selectedOption.value);
        } else {
            setValue("activityArea", null);
        }
    };

    useEffect(() => {
        register({name: "activityArea"});
        register({name: "affiliate"});
        register({name: "name"}, {required: "Ce champ est obligatoire."});
        /*List Entity rest service */
        //service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `entity`, setEntityList)

        /*List Affiliation Organization rest service */
        service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setAffiliationList)

        /*List Affiliation Organization rest service */
        service.getAllForSelect(
            getHostByAppName(NOMENCLATURE_NAME),
            `company_activity_areas/`,
            setActivityAreaList,
            error => console.log(error)
        );

    }, []);

    const addLogo = obj =>{
        setLogo(obj.logo)
    }

    return (
        <div>
            <div>
                <div className="row ma-0">
                    <div className="col-sm-12 ptb-15">
                        <div className="roe-card-style col-sm-12 row">
                            <div className="roe-card-body col-sm-4">
                                <div className="pl-5 pt-5 aligner-wrapper text-center">
                                    <img className="pt-5"
                                         src={logo ? logo : buildingEnterprise}
                                         style={{width:"140px"}}
                                    />
                                    <div style={{marginTop:"10px"}}>
                                        <h3 className="card-title fs-20 bold-text">Informations</h3>
                                        <LogoUpload save={addLogo}/>
                                    </div>
                                </div>
                            </div>
                            <div className="roe-card-body col-sm-8">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col>
                                            <Label>
                                                <p style={{color: "red"}}> {errorMessage}</p>
                                            </Label>
                                            <FormGroup>
                                                <IntlMessages id="setting.organization.designation"/>
                                                <AdaInputText
                                                    name="name"
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>
                                            <Row>
                                                <Col className="col-sm-4">
                                                    <FormGroup>
                                                        <IntlMessages id="setting.organization.affiliation"/>
                                                        <AdaSelect
                                                            isClearable
                                                            name="affiliation"
                                                            errors={errors}
                                                            options={affiliationList}
                                                            onChange={handleChangeAffiliation}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="setting.common.description"/>
                                                        <AdaInputText
                                                            name="description"
                                                            defaultValue={props.organizationObj.description}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="setting.common.telephone"/>
                                                        <AdaInputText
                                                            name="phone"
                                                            defaultValue={props.organizationObj.tel}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="setting.common.contacts.mobile"/>
                                                        <AdaInputText
                                                            name="mobile"
                                                            defaultValue={props.organizationObj.mobile}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="setting.common.contacts.email"/>
                                                        <AdaInputText
                                                            name="email"
                                                            type="email"
                                                            defaultValue={props.organizationObj.email}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <IntlMessages id="setting.common.contacts.fax"/>
                                                        <AdaInputText
                                                            name="fax"
                                                            type="number"
                                                            defaultValue={props.organizationObj.fax}
                                                            errors={errors}
                                                            innerRef={register}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <FormGroup>
                                                <IntlMessages id="setting.organization.web"/>
                                                <AdaInputText
                                                    name="web"
                                                    defaultValue={props.organizationObj.web}
                                                    errors={errors}
                                                    innerRef={register}
                                                />
                                            </FormGroup>


                                            {/*<FormGroup>
                                                <IntlMessages id="setting.organization.entity"/>
                                                <AdaSelect
                                                    name="entity"
                                                    errors={errors}
                                                    isClearable
                                                    options={entityList}
                                                    onChange={handleChangeEntity}
                                                    innerRef={register}
                                                />
                                            </FormGroup>*/}

                                            <FormGroup>
                                                <IntlMessages id="setting.organization.secteur.activite" />
                                                <AdaSelect
                                                    isClearable
                                                    name="activityArea"
                                                    errors={errors}
                                                    isClearable
                                                    options={activityAreaList}
                                                    onChange={handleChangeActivityArea}
                                                    innerRef={register}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <CustomInput
                                                    type="switch"
                                                    id="statusInfClt"
                                                    name="actif"
                                                    defaultChecked={true}
                                                    innerRef={register}
                                                    label={<IntlMessages id="setting.organization.actif"/>}
                                                />
                                            </FormGroup>

                                            <FormGroup>

                                                <AdaButton type="submit"  className="no-border c-AdaNext c-btn-sm c-btn-wide float-sm-right ml-sm-2">
                                                    <IntlMessages id="action.common.suivant.adress.postal"/>
                                                </AdaButton>

                                                <AdaButton  className="no-border c-outline-AdaCancel c-btn-sm c-btn-wide float-sm-right" onClick={props.cancelAction}>
                                                    <IntlMessages id="action.common.cancel"/>
                                                </AdaButton>
                                            </FormGroup>


                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
