import React, { useState } from "react";
import { columnsEtablissement } from "./data/columns";
import { AdaFilterSubHeader, AdaTable, AdaActions } from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import { withRouter } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import {getConfigObject} from "../../../helpers/cookie";
import {getHostByAppName, ORGANIZATION_NAME} from "../../../service/host";
import * as service from "../../../service/crud.service";

const context = "etablissements";
const Etablissements = props => {
  const [etablissements, setEtablissements] = useState([]);
  const [loadingModal, setLoadingModal] = React.useState(false);
  /*user detail */
  const [hideEtablissementDetailPanel, setHideEtablissementDetailPanel] = useState(true);
  /*DataTable state*/
  const [selectedRows, setSelectedRows] = useState([]);
  const [filtered, setFiltered] = useState([]);
  /*Delete action and state modal*/
  const [modalShow, setModalShow] = React.useState(false);
  const [modalSuccessShow, setModalSuccessShow] = useState(false);
  /*Action List*/
  const [actionsList, setActionsList] = useState([]);

  const initActionsList = () => {
    return [
      {
        actionName: <IntlMessages id={`action.common.delete`} />,
        href: "",
        actionHandler: handlerOpenModalDelete,
        disabled: true
      },
      {
        actionName: <IntlMessages id={`action.common.edit`} />,
        href: `/${context}/` + "" + `/edit`,
        actionHandler: null,
        disabled: true
      },
      {
        actionName: <IntlMessages id={`action.common.activer`} />,
        href: "",
        actionHandler: handlerActivateAction,
        disabled: true
      },
      {
        actionName: <IntlMessages id={`action.common.desactiver`} />,
        href: "",
        actionHandler: handlerDisableAction,
        disabled: true
      }
    ];
  };

  /*Initialization Etablissements List */
  React.useEffect(
    () => {
      if (!modalSuccessShow) {
        initList();
      }
    },
    [modalSuccessShow]
  );

  const initList = () =>{
    service.get(getHostByAppName(ORGANIZATION_NAME), context, callbackRes, errorCatch)
  }

  const errorCatch = error =>{

  }

  const callbackRes = data => {
    setEtablissements(data);
    setFiltered(data);
    console.log("liste des Etablissements : ", data);
  };

  /*Create operation List*/
  React.useEffect(
    () => {
      let actionsValue = initActionsList();
      setActionsList(actionsValue);
      setHideEtablissementDetailPanel(true);
      if (selectedRows !== undefined && selectedRows.length > 0) {
        /*Activate : Delete Action*/
        actionsValue[0] = { ...actionsValue[0], disabled: false };
        /*Activate : Activate User Action*/
        actionsValue[2] = { ...actionsValue[2], disabled: false };
        /*Activate : Disable User Action*/
        actionsValue[3] = { ...actionsValue[3], disabled: false };
        if (selectedRows.length === 1) {
          /*Activate : Edit User Action*/
          actionsValue[1] = {
            ...actionsValue[1],
            href: `/${context}/${selectedRows[0].id}/edit`,
            disabled: false
          };
          /*show user detail true*/
          setHideEtablissementDetailPanel(false);
        }
        setActionsList(actionsValue);
        console.log("actions list : ", actionsValue);
      }
    },
    [selectedRows.length]
  );

  const handlerOpenModalDelete = () => {
    setModalShow(true);
  };

  const handlerActivateAction = () => {
    console.log("in Activate action", selectedRows);
    setLoadingModal(true);
    selectedRows.forEach(selectedUser =>
      handlerEnableDisableEtablissement(selectedUser.id, true)
    );
    setModalSuccessShow(true);
  };

  const handlerDisableAction = () => {
    console.log("in Disable action", selectedRows);
    setLoadingModal(true);
    selectedRows.forEach(selectedEtablissement =>
      handlerEnableDisableEtablissement(selectedEtablissement.id, false)
    );
    setModalSuccessShow(true);
  };

  const handlerEnableDisableEtablissement = (etablissementId, status) => {
    console.log("in handlerEnableDisableEtablissement action", etablissementId, status);
    axios
      .put(
        `${getHostByAppName(ORGANIZATION_NAME)}/etablissements/activate_disable_etablissement/${etablissementId}/`,
        {
          status: status
        },getConfigObject()
      )
      .then(res => {
        console.log(res.data);
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(setLoadingModal(false));
  };

  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handlerDeleteAction = () => {
    console.log("in Delete action", selectedRows);
    service.deletes(getHostByAppName(ORGANIZATION_NAME), context, callBackResDelete, ((error)=>{console.log(error)}), selectedRows.map(obj => obj.id))
  };

  const callBackResDelete = () => {
    setModalShow(false);
    setModalSuccessShow(true);
  }

  const actionsComponent = (
    <AdaActions context={context} actionsObj={actionsList} showActions={{exportExcel:false, add:true}}/>
  );

  const adaFilterHeader = (
    <AdaFilterSubHeader
      columns={columnsEtablissement}
      items={etablissements}
      setFiltredItems={setFiltered}
    />
  );

  return (
    <div>
      <div className="row ma-0">
        <div className="col-sm-12 ptb-15">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <AdaTable
                columns={columnsEtablissement}
                data={filtered}
                selectableRows
                actions={actionsComponent}
                onRowSelected={handleRowSelected}
                pagination
              />


              <SweetAlert
                onClick={props.processAction}
                warning
                showCancel
                show={modalShow}
                confirmBtnText="Oui"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Confirmation"
                onConfirm={() => handlerDeleteAction()}
                onCancel={() => setModalShow(false)}
              >
                <IntlMessages id="action.common.confirmation" />
              </SweetAlert>

              <SweetAlert
                success
                show={modalSuccessShow}
                title="Success!"
                onConfirm={() => setModalSuccessShow(false)}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
              >
                <IntlMessages id="action.common.succes" />
              </SweetAlert>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Etablissements);
