import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import {useForm} from "react-hook-form";
import UploadDocument from "../../views/commun/upload";


export default function LogoUpload({objectToSave, save}) {
    const [upload, setUpload] = useState(false);
    const {register, handleSubmit, errors, setValue} = useForm();

    const onSubmitUpload = data => {
        console.log("in submit upload logo ", data);
        setUpload(false);
        save({...objectToSave, logo: data.contents})
    };

    return (
        <div>
            <AdaButton
                className="fas fa-edit more-vert-icon no-border c-AdaNext c-btn-sm c-btn-wide float-sm-none ml-sm-3"
                onClick={() => setUpload(true)}
            > logo
            </AdaButton>

            <UploadDocument
                setUpload={setUpload}
                upload={upload}
                onSubmitUpload={onSubmitUpload}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit}
            />
        </div>
    );
}
