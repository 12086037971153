import React, {useEffect, useState} from "react";
import Gauge from "react-svg-gauge";
import UserInfoWrapper from "./userinfo.style";
import * as service from "../../../../service/crud.service";
import {getHostByAppName, NOMENCLATURE_NAME} from "../../../../service/host";
import {AdaInputText} from "@adaming/ada-react-component";
import PageviewsChartWrapper from "../../../../components/widgets/pageviewsChartWidget/pageviewsChart.style";

const UpdateFiscalStyle = ({icon, title, register, errors, client}) => {

    return (
        <div>
            <PageviewsChartWrapper>
                <div className="page-view-chart-widget-card">

                    <div className="analytics mt-4">
                        <UserInfoWrapper className="fill-height">
                            <div className="roe-shadow-2">

                                <div className="row mb-5">
                        <div className="col-md-4 aligner-wrapper text-center">
                            <img
                                src={icon}
                                style={{width:"100px"}}
                            />
                            <div style={{marginTop:"10px"}}>
                                <h3 className="card-title fs-16 bold-text">{title}</h3>
                            </div>
                        </div>
                        <div className="col-md-4 legend-wrapper">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>

                                    <AdaInputText
                                        name="administrationInformation.rcs"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.administrationInformation && client.administrationInformation.rcs}
                                    />

                                </div>
                                <small className="text-muted ml-3">Code RCS</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="administrationInformation.registrationNumber"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.administrationInformation && client.administrationInformation.registrationNumber}
                                    />
                                </div>
                                <small className="text-muted ml-3">Numéro d'enregistrement</small>
                            </div>

                        </div>

                        <div className="col-md-4 legend-wrapper">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-warning mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="administrationInformation.siret"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.administrationInformation && client.administrationInformation.siret}
                                    />
                                </div>
                                <small className="text-muted ml-3">Siret</small>
                            </div>
                            <div className="d-flex flex-column justify-content-center border-top border-bottom py-3 mt-3 mb-3">
                                <div className="d-flex align-items-center">
                                    <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                    <AdaInputText
                                        name="administrationInformation.intraCommunityTva"
                                        innerRef={register}
                                        errors={errors}
                                        defaultValue={client.administrationInformation && client.administrationInformation.intraCommunityTva}
                                    />
                                </div>
                                <small className="text-muted ml-3">Tva intracommunautaire</small>
                            </div>
                        </div>

                                </div>
                            </div>
                        </UserInfoWrapper>
                    </div>
                </div>
            </PageviewsChartWrapper>
        </div>

    );
};

export default UpdateFiscalStyle;
